import { mainnet, sepolia } from '@starknet-react/chains'
import { StarknetConfig, blastProvider, reddioProvider } from '@starknet-react/core'
import React from 'react'
import { ArgentMobileConnector } from 'starknetkit/argentMobile'
import { InjectedConnector } from 'starknetkit/injected'
import { WebWalletConnector } from 'starknetkit/webwallet'

export function StarknetProvider({ children }: { children: React.ReactNode }): JSX.Element {
    // const rpc = (): { nodeUrl: string } => {
    //     return {
    //         nodeUrl: `https://mainnet-rpc.fibrous.finance`
    //     }
    // };

    const chains = [mainnet, sepolia]
    const apiKey = '83e0811d-e14f-42b0-b556-65246d733267'
    // const apiKey = 'rk-78e6eebf-b107-440c-89dd-889014500fdc'
    const provider = blastProvider({ apiKey })

    const argentMobileOptions = {
        dappName: 'Fibrous Finance',
        projectId: '1c19af9778708c97acf595733be5f30d',
        chainId: 'SN_MAIN',
        description: 'First Aggregator of Starknet',
        url: 'https://app.fibrous.finance',
    }

    const connectors = [
        new InjectedConnector({ options: { id: 'argentX', name: 'Argent X' } }),
        new InjectedConnector({ options: { id: 'braavos', name: 'Braavos' } }),
        new InjectedConnector({ options: { id: 'okxwallet', name: 'OKX Wallet' } }),
        new InjectedConnector({ options: { id: 'bitkeep', name: 'Bitget Wallet' } }),
        new ArgentMobileConnector(argentMobileOptions),
        new WebWalletConnector({ url: 'https://web.argent.xyz' }),
    ]

    return (
        <StarknetConfig chains={chains} provider={provider} connectors={connectors} autoConnect>
            {children}
        </StarknetConfig>
    )
}
